// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

require("bootstrap")
// import "../stylesheets/application";
// document.addEventListener("turbolinks:load", function() {
//     $(function () {
//         $('[data-toggle="tooltip"]').tooltip()
//         $('[data-toggle="popover"]').popover()
//     })
// })

import JQuery from 'jquery';
window.$ = window.JQuery = JQuery;
require("jquery")
// require("moment/locale/de")
// global.moment = require('moment/moment');
require("controllers")
require("trix")
require("@rails/actiontext")
// require("flatpickr")
import flatpickr from "flatpickr";
import { German } from "flatpickr/dist/l10n/de.js"
flatpickr.localize(German);
require("flatpickr/dist/flatpickr.css")

// document.addEventListener("turbolinks:load", () => {
//   $('[data-tooltip-display="true"]').tooltip(),
//   flatpickr("[class='flatpickr']", {})
// })

require("packs/booking")


document.addEventListener("turbolinks:load", () => {
  flatpickr("[data-behaviour='flatpickr']", {
    altInput: true,
    altFormat: "d.m.Y, H:i",
    enableTime: true,
    dateFormat: "Y-m-dTH:i",
    defaultHour: 7
  })

  flatpickr("[data-behaviour='flatpickr_end']", {
    altInput: true,
    altFormat: "d.m.Y, H:i",
    enableTime: true,
    dateFormat: "Y-m-dTH:i",
    defaultHour: 15
  })
})


Rails.start()
Turbolinks.start()
ActiveStorage.start()



// $(document).on("turbolinks:load", function() {
//   $("#test").datetimepicker();
// });


  
  
require("trix")
require("@rails/actiontext")